import { isNil } from "lodash";
import router from "@/router";
import axios from "axios";

interface Callback {
  (message: any): void;
}

export default {
  isAuthenticated() {
    const token = localStorage.getItem("token");

    return !isNil(token);
  },

  get_user_details() {
    const apiUserDetailsPath =
      process.env.VUE_APP_READERBENCH_API_USER_DETAILS_ENDPOINT;

    if (!apiUserDetailsPath) {
      throw new Error(
        "API User details path is not defined in environment variables"
      );
    }

    return axios.get(apiUserDetailsPath);
  },

  get_access_token(
    creds,
    redirect: string,
    onSuccess: Callback,
    onError: Callback
  ) {
    const payload = {
      grant_type: "authorization_code",
      client_id: process.env.VUE_APP_CLIENT_ID,
      code: creds.code,
      redirect_uri: creds.redirect_uri,
    };

    const apiTokenPath = process.env.VUE_APP_READERBENCH_API_TOKEN_ENDPOINT;

    if (!apiTokenPath) {
      throw new Error("API Token path is not defined in environment variables");
    }

    axios
      .post(apiTokenPath, payload)
      .then((response) => {
        if (redirect) {
          router.push({ path: redirect, hash: "#logged_in" });
        }
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem(
          "token_valid_until_timestamp",
          String(response.data.expires_in + Math.floor(Date.now() / 1000))
        );

        try {
          const call_user_details = this.get_user_details()

          call_user_details.then((response) => {
            onSuccess(response.data.username);
          })
          .catch((error) => {
            onError(error);
          });
        } catch (error) {
          onError({response: {data: {error_description: error}}})
        }
      })
      .catch((error) => {
        onError(error);
      });
  },

  refresh_access_token(onSuccess: Callback, onError: Callback) {
    const payload = {
      grant_type: "refresh_token",
      client_id: process.env.VUE_APP_CLIENT_ID,
      refresh_token: localStorage.getItem("refresh_token"),
    };

    const apiTokenPath = process.env.VUE_APP_READERBENCH_API_TOKEN_ENDPOINT;

    if (!apiTokenPath) {
      throw new Error("API Token path is not defined in environment variables");
    }

    axios
      .post(apiTokenPath, payload)
      .then((response) => {
        localStorage.setItem("token", response.data.access_token);
        if (response.data.refresh_token != undefined)
          localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem(
          "token_valid_until_timestamp",
          String(response.data.expires_in + Math.floor(Date.now() / 1000))
        );

        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  },

  logout: function (callback?: Callback) {
    const apiLogoutUrl = process.env.VUE_APP_READERBENCH_API_LOGOUT_URL;

    if (!apiLogoutUrl) {
      throw new Error(
        "API Logout path is not defined in environment variables"
      );
    }

    axios.get(apiLogoutUrl, { withCredentials: true }).then(() => {
      this.clearLocalStorage();
      router.push({ path: "/", hash: "#logged_out" });

      if (callback) {
        callback("You have been logged out");
      }
    });
  },

  clearLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("token_valid_until_timestamp");
    localStorage.removeItem("refresh_token");
  },
};
