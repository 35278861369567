import axios from "axios";
import auth from "./auth";
import router from "@/router";

export function httpInterceptor() {
  axios.interceptors.request.use(async (request) => {
    request.baseURL = `${process.env.VUE_APP_READERBENCH_API_BASE_URL}`;
    request.headers = request.headers ?? {};

    if (request.data) {
      if (request.data.grant_type && request.data.grant_type == "refresh_token") {
        return request;
      }

      if (request.data.grant_type && request.data.grant_type == "authorization_code") {
        return request;
      }
    }

    const token = localStorage.getItem("token");
    request.headers.Authorization = `Bearer ${token}`;

    const now_timestamp = Math.floor(Date.now() / 1000);
    const token_expiration_date_timestamp = Number(
      localStorage.getItem("token_valid_until_timestamp")
    );

    // Check if token it is close to expiration (within 1 hour)
    if (token_expiration_date_timestamp - now_timestamp <= 3600) {
      try {
        await new Promise<void>((resolve, reject) => {
          auth.refresh_access_token(
            (response) => {
              // Set the new access token
              request.headers.Authorization = `Bearer ${response.data.access_token}`;

              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        });
      } catch (error) {
        auth.clearLocalStorage();
        router.push({ path: "/", hash: "#session_expired" });
        return Promise.reject("Your session has expired. Please log in again to continue.");
      }
    }

    return request;
  });
}
